<template>
  <div class="detail-job-box">
    <div class="box">
      <h2 class="job-title">{{ detail.JobTitle }}</h2>
      <p class="job-address">
        <van-icon name="location" color="#ffffff" size="20px" />{{
          detail.Province
        }}
        · {{ detail.City }}
      </p>
      <div class="job-info">
        <div>
          <label>上门时间</label>
          <div>
            {{ detail.ExecuteStartDtStr }}-<br />{{ detail.ExecuteEndDtStr }}
          </div>
        </div>
        <div>
          <label>预计服务天数</label>
          <div>{{ detail.ServiceDayCount }}</div>
        </div>
        <div>
          <label>预算范围</label>
          <div v-if="detail.JobType == 6 && detail.IsProjectJob == 0">
            ￥{{ detail.Amount }}
          </div>
          <!-- <div v-else>￥{{ detail.MinAmount }}-{{ detail.MaxAmount }}</div> -->
          <div v-else>项目议价</div>
        </div>
      </div>
      <div class="job-info2">
        <div>
          <label>工单号</label>
          <div>{{ detail.JobNo }}</div>
        </div>
        <div>
          <label>现场联系人</label>
          <div>{{ detail.ContactP }}</div>
        </div>
        <div>
          <label>发单时间</label>
          <div>{{ detail.PubDtStr }}</div>
        </div>
      </div>
      <div
        class="job-info3 min-title"
        v-if="this.treeArr != undefined && this.treeArr.length > 0"
      >
        <h5>服务产品</h5>
        <div v-for="(item, index) in treeArr" :key="index">
          <!-- <div class="info-item3" v-for="(item, index) in treeArr" :key="index">
                        <div class="item3-label">
                            <label>产品信息（{{index}}）</label>
                            <p>数量：<span>{{item.count}}</span></p>
                        </div>
                        <div class="item3-con">{{item.str}}</div>
                    </div> -->

          <div class="info-item3">
            <div class="item3-img">
              <!-- <img :src="require('../../assets/404.gif')" /> -->
              <img
                :src="
                  item.ThreeProductImgUrl == ''
                    ? require('../../assets/no-img.jpg')
                    : item.ThreeProductImgUrl
                "
              />
            </div>
            <div class="item3-con">
              <div>{{ item.ServiceOfferName }}</div>
              <div>服务描述：{{ item.Content }}</div>
              <div>
                <span>{{ item.ProductDomainName }}</span>
                <span>{{
                  item.ServiceType == 1 ? "标准计费" : "项目议价"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="min-title">
        <h5>服务信息</h5>
        <div class="job-info2">
          <div>
            <label>所属行业</label>
            <div>{{ detail.IndustryStr }}</div>
          </div>
          <div>
            <label>所需工程师</label>
            <div>{{ detail.NeedWorkerCount }}</div>
          </div>
          <div>
            <label>服务需求描述</label>
            <div>{{ detail.ServiceDescription }}</div>
          </div>
          <div>
            <label>现场资料</label>
            <div class="imgCon" v-if="detail.SendReplenishFiles.length > 0">
              <div
                class="itemImg"
                v-for="(item, index) in detail.SendReplenishFiles"
                :key="index"
              >
                <a v-if="item.FileType == 2" :href="item.FileUrl">
                  <img :src="item.FileUrl" />
                </a>

                <a v-if="item.FileType == 1" :href="item.FileUrl">
                  <img :src="item.IconUrl" />
                </a>
              </div>
              <div style="clear: both"></div>
            </div>
            <div v-else>无</div>
          </div>
        </div>
      </div>

      <div
        class="min-title"
        v-if="detail.ServiceProblemList && detail.ServiceProblemList.length > 0"
      >
        <h5>特殊说明</h5>
        <div class="job-info4">
          <div v-for="(item, index) in detail.ServiceProblemList" :key="index">
            <div>{{ item.Title }}</div>
            <div>
              <span
                v-for="(item2, index2) in item.ProblemOptionList"
                :key="index2"
                >{{ item2.Content }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />

    <div class="ercode_big" v-show="isShowErCode">
      <div class="title">截图保存微信扫码添加</div>
      <img
        class="cha"
        src="../../../public/static/job/cha.png"
        @click="isShowErCode = false"
      />
      <img class="code" src="../../../public/static/job/codeImg.png" />
    </div>
    <!-- <div class="ercode" @click="isShowErCode=!isShowErCode">
      <img src="../../../public/static/job/codeImg.png" />
    </div> 
    <div class="openApp">
      <div  class="serverPhone">客服电话：<a @click="phone">021-60956592</a></div>
      <button @click="openAPP">打 开 APP</button>
    </div>-->

    <div class="btn_con">
      <div @click="phone">
        <!-- 电话咨询：<br> 021-60956592  -->
        <button @click="phone">电话咨询<br />02160956592</button>
      </div>
      <div>
        <button @click="openAPP">打开App投标</button>
      </div>
      <div>
        <button @click="scanQr">
          点击扫码<br />咨询客服
        </button>
      </div>
    </div>
    <div class="wx-mask" v-if="isShowWX" @click="isShowWX = false">
      <img src="../../../public/static/job/guanbi.png" class="guanbi" />
    </div>
  </div>
</template>
<script>
let u = navigator.userAgent;
let ua = navigator.userAgent.toLowerCase();
import { GetJobDetailIndex, AddH5Log } from "@/common/api.js";
export default {
  data() {
    return {
      isShowWX: false,

      jobId: "",
      shareUserId: "",
      shareUserType: "",

      detail: {},
      treeArr: [],

      isShowErCode: false,
    };
  },
  created() {
    this.jobId = Number(this.$route.query.jobId);
    this.shareUserId = Number(this.$route.query.shareUserId) || 0;
    this.shareUserType = Number(this.$route.query.shareUserType) || 0;
    this.getDetailInfo();
    //添加数据埋点
    this.addH5Log(1);
  },
  methods: {
    async getDetailInfo() {
      let data = {};
      data.JobId = this.jobId;
      data.UserCode = "p" + this.shareUserId;
      let res = await GetJobDetailIndex(data);
      console.log(res);
      this.detail = res.Data;

      this.treeArr = this.detail.ProductTrees;
      // for(let i in this.detail.ProductTrees) {
      //     let objTree = {}
      //     let a = this.detail.ProductTrees[i]
      //     objTree.str = `${a.ProductDomainName}/${a.ServiceStageName}/${a.ProductCategoryName}/${a.BrandName}/${a.SerialName}`
      //     objTree.count = a.Count
      //     this.treeArr.push(objTree)
      // }
    },
    openAPP: function () {
      let self = this;
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isShowWX = true;
        return;
      }
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        console.log("安卓");
        window.location.href = `gongkongsupai://com.gongkong.supai?launchType=5&jobId=${self.jobId}&shareUserId=${self.shareUserId}&shareUserType=${self.shareUserType}`;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            //添加数据埋点
            this.addH5Log(2);

          window.location.href =
            "https://wx.insupai.com/download/download.html";
          clearTimeout(this.timer);
        }, 3000);
      } else if (u.indexOf("iPhone") > -1) {
        console.log("苹果");
        window.location.href = `gongkongsupai://param?launchType=5&jobId=${self.jobId}&shareUserId=${self.shareUserId}&shareUserType=${self.shareUserType}`;
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            //添加数据埋点
            this.addH5Log(2);

          window.location.href =
            "https://wx.insupai.com/download/download.html";
          clearTimeout(this.timer);
        }, 3000);
      }
    },

    phone() {
        //添加数据埋点
        this.addH5Log(3);

      if (navigator.userAgent.match(/iPhone/i)) {
        var win = window.open("tel:021-60956592", "_blank");
        win.close();
      } else {
        // 直接跳转到拨号界面
        window.location.href = "tel:021-60956592";
      }
    },

    //扫码
    scanQr(){
      //添加数据埋点
      this.addH5Log(4);

      this.isShowErCode = !this.isShowErCode;
    },
    //添加h5数据埋点,操作类别:1页面打开，2投标按钮点击，3客服电话点击，4企业微信点击
    addH5Log(operationType) {
      var data = {
        OperationType: operationType,
        UserId: this.shareUserId,
        UserType: this.shareUserType,
        isManageApi:true
      };
      let res = AddH5Log(data).then((res) => {
        console.log("AddH5Log，operationType:" + JSON.stringify(res));
      });
    },
  },
};
</script>
<style lang="scss">
@import "~@/style/base.scss";
.detail-job-box {
  width: 10rem;
  margin: 0 auto;
  //   height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-size: contain;
  background-color: #f9f9f9;
  background-position: center px2rem(-80px);
  padding-block: px2rem(100px);
  @include bg("~static/share/orderDetailTop.png");

  .box {
    padding: px2rem(60px) px2rem(28px);
    .job-title {
      color: #ffffff;
      font-weight: bold;
      font-size: px2rem(40px);
      line-height: px2rem(46px);
    }
    .job-address {
      margin-top: px2rem(42px);
      margin-bottom: px2rem(52px);
      display: flex;
      color: #ffffff;
      font-weight: 500;
      font-size: px2rem(28px);
      align-items: center;
      line-height: normal;
    }
    .job-info {
      background: #ffffff;
      min-height: px2rem(180px);
      border: 1px solid #f9f9f9;
      border-radius: px2rem(20px);
      padding: px2rem(34px) 0;
      display: flex;
      justify-content: space-around;
      line-height: normal;
      margin-bottom: px2rem(30px);
      box-shadow: 0 0 15px rgba(102, 102, 102, 0.4);
      > div {
        flex: 0 0 33%;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
          border-right: none;
        }
        &:first-child {
          > div {
            font-weight: 400;
            font-size: px2rem(28px);
          }
        }
        > div {
          min-height: px2rem(60px);
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: normal;
          font-size: px2rem(36px);
          color: #333333;
          font-weight: 500;
        }
      }
      label {
        display: block;
        text-align: center;
        color: #999999;
        font-size: px2rem(26px);
        line-height: px2rem(26px);
        padding-bottom: px2rem(26px);
      }
    }
    .job-info2 {
      background: white;
      padding: 0px 5px;
      border-radius: 5px;
    }
    .job-info2 {
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: px2rem(70px);

        label {
          color: #666666;
          font-size: px2rem(28px);
          min-width: 1.5rem;
        }
        div {
          font-size: px2rem(30px);
          color: #333333;
          font-weight: 500;
          //   border: 1px solid blue;

          .itemImg {
            height: 100px;
            width: 100px;
            float: left;
            margin-left: 5px;
            margin-bottom: 5px;
          }
          .itemImg img {
            height: 100px;
            width: 100px;
            border: 0.5px solid #999999;
          }
          .itemImg img a {
            display: block;
          }
        }
      }
    }

    .min-title {
      h5 {
        padding-top: px2rem(40px);
        padding-bottom: px2rem(36px);
        font-size: px2rem(32px);
        color: #333333;
        font-weight: bold;
      }
    }
    .job-info3 {
      > div {
        background: white;

        min-height: px2rem(180px);
        border: 1px solid #f9f9f9;
        border-radius: px2rem(20px);
        padding: 0 px2rem(20px);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.4);
        margin-bottom: 5px;
        .info-item3 {
          //   margin: px2rem(34px) 0;
          padding: 2px;
          display: flex;
          align-items: center;
          //   border: 1px solid red;
          .item3-img {
            // border: 1px solid red;
          }
          .item3-img img {
            width: 100px;
            height: 100px;
          }
          //   .item3-label {
          //     display: flex;
          //     align-items: center;
          //     justify-content: space-between;
          //     label,
          //     p {
          //       font-size: px2rem(28px);
          //       color: #666666;
          //       span {
          //         color: #333333;
          //       }
          //     }
          //   }
          .item3-con {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // border: 1px solid red;
            font-size: px2rem(28px);
            height: 100%;
            color: #333333;

            height: 2.5rem;
            padding: 0px 5px;
            // line-height: px2rem(80px);
          }
          .item3-con > div {
            // flex-grow: 1;
            // border: 1px solid red;
          }
          .item3-con > div:nth-child(1) {
            font-weight: bold;
            text-overflow: ellipsis;
            // white-space: nowrap; border: 1px solid red;
            padding: 5px 0px;
          }
          .item3-con > div:nth-child(2) {
            color: #999999;
            overflow: hidden;
            font-size: 13px;
            padding: 6px 0px;
            flex-grow: 1;
            flex-wrap: wrap;
            // border: 1px solid red;
          }
          .item3-con > div:nth-child(3) {
            padding: 5px 0px;
          }
          .item3-con > div:nth-child(3) > span:nth-child(1) {
            border: 1px solid red;
            color: red;
            font-size: px2rem(12px);
            padding: 3px;
            margin-left: 5px;
          }
          .item3-con > div:nth-child(3) > span:nth-child(2) {
            color: white;
            font-size: px2rem(12px);
            padding: 3px;
            background-color: #fc8e2d;
            margin-left: 5px;
          }
        }
      }
    }

    .job-info4 {
      background: white;
      padding: 0px 5px;
      border-radius: 5px;
      font-size: px2rem(28px);
      color: #333333;
    }
    .job-info4 {
      > div {
        min-height: px2rem(70px);
        padding: 3px;

        div {
          padding: 3px;
        }
        div:nth-child(1) {
          font-weight: bold;
        }
      }
    }
  }

  .ercode_big {
    width: 70%;
    height: auto;
    position: fixed;
    margin: auto;
    left: 50%;
    bottom: px2rem(160px);
    transform: translate(-50%, 0%); /* 用于2D转换 */
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.6);
    .title {
      text-align: center;
      font-size: 16px;
      padding: 3px;
    }
    img.code {
      width: 100%;
      height: auto;
    }
    img.cha {
      position: absolute;
      right: -10px;
      top: -10px;
      width: px2rem(60px);
      height: px2rem(60px);
    }
  }

  .ercode {
    // border:1px solid red;
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: 180px;
    border-radius: 25px;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.7);
    background: #ffffff;
    img {
      width: 45px;
      height: 45px;
      border-radius: 23px;
      margin-top: 2.5px;
      margin-left: 2.5px;
    }
  }

  // .openApp {
  //   background: #ffffff;
  //   position: fixed;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   button {
  //     display: block;
  //     width: 90%;
  //     height: px2rem(88px);
  //     background: #f75959;
  //     border-radius: 44px;
  //     font-size: px2rem(32px);
  //     color: #ffffff;
  //     box-sizing: border-box;
  //     // margin: px2rem(50px) px2rem(60px);
  //     margin:px2rem(20px)  auto;
  //   }

  //   .serverPhone{
  //     height: 50px;
  //     width: 100%;
  //     max-width: 750px;
  //     background-color: #fdf6ec;
  //     color: #e6a23c;
  //     padding: 0 8px;
  //     text-align: center;
  //     line-height: 50px;
  //      font-size: 20px;
  //     a{
  //         line-height: 50px;
  //         font-size: 20px;
  //         text-decoration: underline;
  //         font-weight: normal;
  //       }
  //   }

  // }

  .btn_con {
    // background: #ffffff;
    background: white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    height: 70px;
    box-shadow: 0 0 15px rgba(102, 102, 102, 0.4);
    background-color: white;
    // color:white;
    div {
      flex: 1;
      //  border: 1px solid blue;
      text-align: center;

      button {
        font-weight: bold;
        font-size: 16px;
        height: 50px;
        background-color: #f75959;
        // border-radius: 25px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0px 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        // padding: 0px 10px;
        color: #ffffff;
        margin-top: 10px;
        width: 95%;
      }
    }

    //  div:nth-child(1){
    //   //  line-height: 50px;
    //    text-align: center;
    //   //  border-right: 1px solid rgba(0, 0, 0, 0.1);

    //   //  text-decoration: underline
    //  }
    //  div:nth-child(2){
    //   //  line-height: 20px;
    //    text-align: center;
    //   //  border-right: 1px solid rgba(0, 0, 0, 0.1);
    //   //  line-height: 50px;
    //   //  text-decoration: underline
    //  }
    //  div:nth-child(3){
    //   //  line-height: 65px;
    //    text-align: center;
    //   //  line-height: 50px;
    //   //  text-decoration: underline;
    //  }
  }
}
.wx-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  @include bg("~static/h5/invite/cover.png");
  background-size: cover;

  .guanbi {
    position: absolute;
    width: 32%;
    bottom: 25%;
    left: 34%;
    z-index: 130;
  }
}
</style>